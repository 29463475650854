// import React, { useState, useEffect } from 'react';
// import { QRCodeCanvas } from 'qrcode.react';
// import './Payment.css';

// const Payment = () => {
//   const [qrCodeUrl, setQrCodeUrl] = useState(null);
//   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown
//   const amountToBePaid = 500; // You can dynamically pass this value

//   const transactionId = "SAMPLE_TRANSACTION_ID"; // Replace with actual data

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   const qrCodeValue = `upi://pay?pa=sample@upi&pn=SampleName&am=${amountToBePaid}&cu=INR`;

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         <div className="header">
//           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//         </div>

//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>

//         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//         {/* Displaying the amount to be paid */}
//         <p className="amount-to-be-paid">Amount to be paid: ₹{amountToBePaid}</p>

//         <div className="qr-and-steps">
//           <div className="qr-section">
//             <QRCodeCanvas value={qrCodeValue} size={300} className="qr-code" />
//             <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//           </div>

//           {/* Steps to complete the payment */}
//           <div className="payment-steps">
//             <p className="step-text fade-in step1">Open your UPI app</p>
//             <div className="down-arrow fade-in step1">&#x2193;</div>
//             <p className="step-text fade-in step2">Scan the QR code</p>
//             <div className="down-arrow fade-in step2">&#x2193;</div>
//             <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//           </div>
//         </div>

//         <div className="payment-options">
//           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const [qrCode, setQrCode] = useState('');
//   const [amount, setAmount] = useState(0);
//   const [transactionId, setTransactionId] = useState('');
//   const location = useLocation();

//   // Extract the transaction ID from the URL query parameters
//   const query = new URLSearchParams(location.search);
//   const transactionIdFromUrl = query.get('transaction_id');

//   useEffect(() => {
//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);

//       // Fetch the QR code from the backend using the transaction ID
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl);  // Debug: Transaction ID in frontend

//       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)  // Replace with your actual API endpoint
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code);  // Debug: QR code received
//           setQrCode(data.qr_code);      // Set the base64 QR code
//           setAmount(data.amount);        // Set the amount to be displayed
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error);  // Debug: Log any error in fetching QR code
//         });
//     }
//   }, [transactionIdFromUrl]);

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         <h2>Scan and Pay using UPI</h2>
//         <p>Amount: ₹{amount}</p>

//         {/* Display QR code if available */}
//         {qrCode ? (
//           <img 
//             src={`data:image/png;base64,${qrCode}`}  // Display the base64 QR code
//             alt="UPI QR Code"
//             className="qr-code"
//           />
//         ) : (
//           <p>Loading QR Code...</p>
//         )}

//         <div>
//           <p>Transaction ID: {transactionId}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect } from 'react';
// import './Payment.css';
// import { useLocation } from 'react-router-dom'; // Ensure this is imported to use location-based parameters

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Will hold the base64 QR code
//   const [amount, setAmount] = useState(0); // Will hold the payment amount
//   const [transactionId, setTransactionId] = useState('');
//   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown

//   const location = useLocation(); // To get transaction ID from URL parameters

//   useEffect(() => {
//     // Extract transaction ID from URL query parameters
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');

//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

//       // Fetch the QR code from the backend using the transaction ID
//       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code); // Debugging log
//           setQrCode(data.qr_code); // Set the base64 QR code
//           setAmount(data.amount); // Set the amount to be displayed
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
//         });
//     }
//   }, [location.search]);

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         <div className="header">
//           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//         </div>

//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>

//         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//         {/* Displaying the amount to be paid */}
//         <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//         <div className="qr-and-steps">
//           <div className="qr-section">
//             {/* Render the QR code fetched from the backend */}
//             {qrCode ? (
//               <img 
//                 src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
//                 alt="UPI QR Code"
//                 className="qr-code"
//               />
//             ) : (
//               <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
//             )}
//             <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//           </div>

//           {/* Steps to complete the payment */}
//           <div className="payment-steps">
//             <p className="step-text fade-in step1">Open your UPI app</p>
//             <div className="down-arrow fade-in step1">&#x2193;</div>
//             <p className="step-text fade-in step2">Scan the QR code</p>
//             <div className="down-arrow fade-in step2">&#x2193;</div>
//             <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//           </div>
//         </div>

//         <div className="payment-options">
//           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect } from 'react';
// import './Payment.css';
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Base64 QR code
//   const [amount, setAmount] = useState(0); // Payment amount
//   const [transactionId, setTransactionId] = useState('');
//   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown
//   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Status of the payment
//   const location = useLocation();

//   // Fetch the transaction ID from the URL parameters
//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');
//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);

//       // Fetch the QR code for the transaction ID
//       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           setQrCode(data.qr_code);
//           setAmount(data.amount);
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error);
//         });

//       // Poll the backend for payment status every 5 seconds
//       const statusCheckInterval = setInterval(() => {
//         fetch(`http://localhost:8067/api/check-payment-status/${transactionIdFromUrl}`)
//           .then(response => response.json())
//           .then(data => {
//             console.log('Payment Status:', data.status);
//             if (data.status.toLowerCase() === 'success') {
//               clearInterval(statusCheckInterval); // Stop polling if success
//               setPaymentStatus('SUCCESS');
//             } else if (data.status.toLowerCase() === 'failed') {
//               clearInterval(statusCheckInterval); // Stop polling if failed
//               setPaymentStatus('FAILED');
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching payment status:', error);
//           });
//       }, 5000);

//       return () => clearInterval(statusCheckInterval); // Clear the interval when component unmounts
//     }
//   }, [location.search]);

//   // Timer countdown for QR expiry
//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         <div className="header">
//           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//         </div>

//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>

//         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//         {/* Display the amount to be paid */}
//         <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//         {paymentStatus === 'SUCCESS' ? (
//           // Display success animation when the payment is successful
//           <div className="success-animation">
//             <div className="tick-mark"></div>
//             <p className="success-text">Payment Successful!</p>
//           </div>
//         ) : (
//           <div className="qr-and-steps">
//             <div className="qr-section">
//               {/* Display the QR code or show loading text */}
//               {qrCode ? (
//                 <img 
//                   src={`data:image/png;base64,${qrCode}`} 
//                   alt="UPI QR Code"
//                   className="qr-code"
//                 />
//               ) : (
//                 <p>Loading QR Code...</p>
//               )}
//               <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//             </div>

//             {/* Steps to complete the payment */}
//             <div className="payment-steps">
//               <p className="step-text fade-in step1">Open your UPI app</p>
//               <div className="down-arrow fade-in step1">&#x2193;</div>
//               <p className="step-text fade-in step2">Scan the QR code</p>
//               <div className="down-arrow fade-in step2">&#x2193;</div>
//               <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//             </div>
//           </div>
//         )}

//         <div className="payment-options">
//           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payment;


// import React, { useState, useEffect } from 'react';
// import './Payment.css'; // Assuming you have your previous styles in this CSS file
// import { useLocation } from 'react-router-dom'; // To handle URL parameters

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
//   const [amount, setAmount] = useState(0); // Amount to be displayed
//   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
//   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8-minute countdown
//   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
//   const [success, setSuccess] = useState(false); // For success animation
//   const [failed, setFailed] = useState(false); // For failed payment status

//   const location = useLocation(); // To get transaction ID from URL parameters

//   useEffect(() => {
//     // Extract transaction ID from URL query parameters
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');

//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

//       // Fetch the QR code from the backend using the transaction ID
//       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
//       // fetch(`http://localhost:8019/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code); // Debugging log
//           setQrCode(data.qr_code); // Set the base64 QR code
//           setAmount(data.amount); // Set the amount to be displayed
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
//         });
//     }
//   }, [location.search]);

//   useEffect(() => {
//     // Poll the backend every 3 seconds to check the payment status
//     const interval = setInterval(() => {
//       if (transactionId) {
//         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//         // fetch(`http://localhost:8019/api/check-payment-status/${transactionId}`)
//           .then(response => response.json())
//           .then(data => {
//             console.log("Payment status:", data.status);
//             setPaymentStatus(data.status); // Set the payment status
            
//             if (data.status === 'SUCCESS') {
//               // Trigger success animation
//               setQrCode(''); // Clear the QR code
//               setSuccess(true); // Show success animation
//               clearInterval(interval); // Stop polling
//             } else if (data.status === 'FAILED') {
//               // Handle failed payment
//               setFailed(true);
//               clearInterval(interval); // Stop polling
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching payment status:', error);
//           });
//       }
//     }, 3000); // Poll every 3 seconds

//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, [transactionId]);

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         {/* Success animation */}
//         {success ? (
//           <div className="success-animation">
//             <h2>Payment Successful!</h2>
//             <div className="checkmark-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-animation">
//             <h2>Payment Failed</h2>
//             <div className="failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <div className="header">
//               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//             </div>

//             <div className="logo-section">
//               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//             </div>

//             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//             {/* Displaying the amount to be paid */}
//             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//             <div className="qr-and-steps">
//               <div className="qr-section">
//                 {/* Render the QR code fetched from the backend */}
//                 {qrCode ? (
//                   <img 
//                     src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
//                     alt="UPI QR Code"
//                     className="qr-code"
//                   />
//                 ) : (
//                   <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
//                 )}
//                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//               </div>

//               {/* Steps to complete the payment */}
//               <div className="payment-steps">
//                 <p className="step-text fade-in step1">Open your UPI app</p>
//                 <div className="down-arrow fade-in step1">&#x2193;</div>
//                 <p className="step-text fade-in step2">Scan the QR code</p>
//                 <div className="down-arrow fade-in step2">&#x2193;</div>
//                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//               </div>
//             </div>

//             <div className="payment-options">
//               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;


// import React, { useState, useEffect } from 'react';
// import './Payment.css'; // Assuming you have your previous styles in this CSS file
// import { useLocation } from 'react-router-dom'; // To handle URL parameters

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
//   const [amount, setAmount] = useState(0); // Amount to be displayed
//   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
//   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8-minute countdown
//   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
//   const [success, setSuccess] = useState(false); // For success animation
//   const [failed, setFailed] = useState(false); // For failed payment status

//   const location = useLocation(); // To get transaction ID from URL parameters

//   useEffect(() => {
//     // Extract transaction ID from URL query parameters
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');

//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

//       // Fetch the QR code from the backend using the transaction ID
//       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code); // Debugging log
//           setQrCode(data.qr_code); // Set the base64 QR code
//           setAmount(data.amount); // Set the amount to be displayed
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
//         });
//     }
//   }, [location.search]);

//   useEffect(() => {
//     // Poll the backend every 3 seconds to check the payment status
//     const interval = setInterval(() => {
//       if (transactionId) {
//         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//           .then(response => response.json())
//           .then(data => {
//             console.log("Payment status:", data.status);
//             setPaymentStatus(data.status); // Set the payment status

//             if (data.status === 'SUCCESS') {
//               // Trigger success animation
//               setQrCode(''); // Clear the QR code
//               setSuccess(true); // Show success animation
//               clearInterval(interval); // Stop polling

//               // Redirect to the callback URL after success
//               if (data.payin_callback_url) {
//                 window.location.href = data.payin_callback_url;
//               }
//             } else if (data.status === 'FAILED') {
//               // Handle failed payment
//               setFailed(true);
//               clearInterval(interval); // Stop polling

//               // Redirect to the callback URL after failure
//               if (data.payin_callback_url) {
//                 window.location.href = data.payin_callback_url;
//               }
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching payment status:', error);
//           });
//       }
//     }, 3000); // Poll every 3 seconds

//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, [transactionId]);

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         {/* Success animation */}
//         {success ? (
//           <div className="success-animation">
//             <h2>Payment Successful!</h2>
//             <div className="checkmark-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-animation">
//             <h2>Payment Failed</h2>
//             <div className="failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <div className="header">
//               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//             </div>

//             <div className="logo-section">
//               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//             </div>

//             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//             {/* Displaying the amount to be paid */}
//             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//             <div className="qr-and-steps">
//               <div className="qr-section">
//                 {/* Render the QR code fetched from the backend */}
//                 {qrCode ? (
//                   <img 
//                     src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
//                     alt="UPI QR Code"
//                     className="qr-code"
//                   />
//                 ) : (
//                   <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
//                 )}
//                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//               </div>

//               {/* Steps to complete the payment */}
//               <div className="payment-steps">
//                 <p className="step-text fade-in step1">Open your UPI app</p>
//                 <div className="down-arrow fade-in step1">&#x2193;</div>
//                 <p className="step-text fade-in step2">Scan the QR code</p>
//                 <div className="down-arrow fade-in step2">&#x2193;</div>
//                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//               </div>
//             </div>

//             <div className="payment-options">
//               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect, useRef } from 'react';
// import './Payment.css'; // Assuming you have your previous styles in this CSS file
// import { useLocation } from 'react-router-dom'; // To handle URL parameters

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
//   const [amount, setAmount] = useState(0); // Amount to be displayed
//   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
//   const [expiryTime, setExpiryTime] = useState(1.65 * 60); // 1-minute countdown
//   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
//   const [success, setSuccess] = useState(false); // For success animation
//   const [failed, setFailed] = useState(false); // For failed payment status
//   const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL

//   const location = useLocation(); // To get transaction ID from URL parameters
//   const pollingIntervalRef = useRef(null); // To store polling interval ID

//   useEffect(() => {
//     // Extract transaction ID from URL query parameters
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');

//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

//       // Fetch the QR code from the backend using the transaction ID
//       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code); // Debugging log
//           setQrCode(data.qr_code); // Set the base64 QR code
//           setAmount(data.amount); // Set the amount to be displayed
//           setCallbackUrl(data.payin_callback_url); // Store the callback URL
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
//         });
//     }
//   }, [location.search]);

//   useEffect(() => {
//     if (transactionId) {
//       pollingIntervalRef.current = setInterval(() => {
//         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//           .then(response => response.json())
//           .then(data => {
//             console.log("Payment status:", data.status);
//             setPaymentStatus(data.status); // Set the payment status

//             if (data.status === 'SUCCESS') {
//               // Trigger success animation
//               setQrCode(''); // Clear the QR code
//               setSuccess(true); // Show success animation
//               clearInterval(pollingIntervalRef.current); // Stop polling

//               // Redirect to the callback URL after success
//               if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             } else if (data.status === 'FAILED') {
//               // Handle failed payment
//               setFailed(true);
//               clearInterval(pollingIntervalRef.current); // Stop polling

//               // Redirect to the callback URL after failure
//               if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching payment status:', error);
//           });
//       }, 3000); // Poll every 3 seconds
//     }

//     return () => {
//       clearInterval(pollingIntervalRef.current);
//     };
//   }, [transactionId, callbackUrl]);

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   useEffect(() => {
//     if (expiryTime === 0 && paymentStatus === 'PENDING') {
//       // Handle failed payment due to expiry
//       setFailed(true);
//       setPaymentStatus('FAILED');
//       clearInterval(pollingIntervalRef.current);

//       // Redirect to the callback URL after failure
//       if (callbackUrl) {
//         window.location.href = callbackUrl;
//       }
//     }
//   }, [expiryTime, paymentStatus, callbackUrl]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         {/* Success animation */}
//         {success ? (
//           <div className="success-animation">
//             <h2>Payment Successful!</h2>
//             <div className="checkmark-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-animation">
//             <h2>Payment Failed</h2>
//             <div className="failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <div className="header">
//               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//             </div>

//             <div className="logo-section">
//               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//             </div>

//             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//             {/* Displaying the amount to be paid */}
//             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//             <div className="qr-and-steps">
//               <div className="qr-section">
//                 {/* Render the QR code fetched from the backend */}
//                 {qrCode ? (
//                   <img
//                     src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
//                     alt="UPI QR Code"
//                     className="qr-code"
//                   />
//                 ) : (
//                   <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
//                 )}
//                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//               </div>

//               {/* Steps to complete the payment */}
//               <div className="payment-steps">
//                 <p className="step-text fade-in step1">Open your UPI app</p>
//                 <div className="down-arrow fade-in step1">&#x2193;</div>
//                 <p className="step-text fade-in step2">Scan the QR code</p>
//                 <div className="down-arrow fade-in step2">&#x2193;</div>
//                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//               </div>
//             </div>

//             <div className="payment-options">
//               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect, useRef } from 'react';
// import './Payment.css'; // Assuming you have your previous styles in this CSS file
// import { useLocation } from 'react-router-dom'; // To handle URL parameters

// const Payment = () => {
//   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
//   const [amount, setAmount] = useState(0); // Amount to be displayed
//   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
//   const [expiryTime, setExpiryTime] = useState(1.65 * 60); // 1.65 minutes countdown
//   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
//   const [success, setSuccess] = useState(false); // For success animation
//   const [failed, setFailed] = useState(false); // For failed payment status
//   const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL
//   const [successRedirectUrl, setSuccessRedirectUrl] = useState(''); // Added
//   const [failureRedirectUrl, setFailureRedirectUrl] = useState(''); // Added

//   const location = useLocation(); // To get transaction ID from URL parameters
//   const pollingIntervalRef = useRef(null); // To store polling interval ID

//   useEffect(() => {
//     // Extract transaction ID from URL query parameters
//     const query = new URLSearchParams(location.search);
//     const transactionIdFromUrl = query.get('transaction_id');

//     if (transactionIdFromUrl) {
//       setTransactionId(transactionIdFromUrl);
//       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

//       // Fetch the QR code from the backend using the transaction ID
//       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
//         .then(response => response.json())
//         .then(data => {
//           console.log("QR Code received from backend:", data.qr_code); // Debugging log
//           setQrCode(data.qr_code); // Set the base64 QR code
//           setAmount(data.amount); // Set the amount to be displayed
//           setCallbackUrl(data.payin_callback_url); // Store the callback URL
//           setSuccessRedirectUrl(data.success_redirect_url); // Store success redirect URL
//           setFailureRedirectUrl(data.failure_redirect_url); // Store failure redirect URL
//         })
//         .catch(error => {
//           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
//         });
//     }
//   }, [location.search]);

//   useEffect(() => {
//     if (transactionId) {
//       pollingIntervalRef.current = setInterval(() => {
//         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//           .then(response => response.json())
//           .then(data => {
//             console.log("Payment status:", data.status);
//             setPaymentStatus(data.status); // Set the payment status

//             if (data.status === 'SUCCESS') {
//               // Trigger success animation
//               setQrCode(''); // Clear the QR code
//               setSuccess(true); // Show success animation
//               clearInterval(pollingIntervalRef.current); // Stop polling

//               // Redirect to the success redirect URL after success
//               setTimeout(() => {
//                 if (successRedirectUrl) {
//                   window.location.href = successRedirectUrl;
//                 } else if (callbackUrl) {
//                   window.location.href = callbackUrl;
//                 }
//               }, 2000);
//             } else if (data.status === 'FAILED') {
//               // Handle failed payment
//               setFailed(true);
//               clearInterval(pollingIntervalRef.current); // Stop polling

//               // Redirect to the failure redirect URL after failure
//               setTimeout(() => {
//                 if (failureRedirectUrl) {
//                   window.location.href = failureRedirectUrl;
//                 } else if (callbackUrl) {
//                   window.location.href = callbackUrl;
//                 }
//               }, 2000);
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching payment status:', error);
//           });
//       }, 3000); // Poll every 3 seconds
//     }

//     return () => {
//       clearInterval(pollingIntervalRef.current);
//     };
//   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

//   useEffect(() => {
//     if (expiryTime > 0) {
//       const timer = setInterval(() => {
//         setExpiryTime((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [expiryTime]);

//   useEffect(() => {
//     if (expiryTime === 0 && paymentStatus === 'PENDING') {
//       // Handle failed payment due to expiry
//       setFailed(true);
//       setPaymentStatus('FAILED');
//       clearInterval(pollingIntervalRef.current);

//       // Redirect to the failure redirect URL after timeout
//       setTimeout(() => {
//         if (failureRedirectUrl) {
//           window.location.href = failureRedirectUrl;
//         } else if (callbackUrl) {
//           window.location.href = callbackUrl;
//         }
//       }, 2000);
//     }
//   }, [expiryTime, paymentStatus, callbackUrl, failureRedirectUrl]);

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = Math.floor(timeInSeconds % 60);
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     <div className="payment-widget-container">
//       <div className="payment-widget">
//         {/* Success animation */}
//         {success ? (
//           <div className="success-animation">
//             <h2>Payment Successful!</h2>
//             <div className="checkmark-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-animation">
//             <h2>Payment Failed</h2>
//             <div className="failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <div className="header">
//               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
//             </div>

//             <div className="logo-section">
//               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//             </div>

//             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

//             {/* Displaying the amount to be paid */}
//             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

//             <div className="qr-and-steps">
//               <div className="qr-section">
//                 {/* Render the QR code fetched from the backend */}
//                 {qrCode ? (
//                   <img
//                     src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
//                     alt="UPI QR Code"
//                     className="qr-code"
//                   />
//                 ) : (
//                   <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
//                 )}
//                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
//               </div>

//               {/* Steps to complete the payment */}
//               <div className="payment-steps">
//                 <p className="step-text fade-in step1">Open your UPI app</p>
//                 <div className="down-arrow fade-in step1">&#x2193;</div>
//                 <p className="step-text fade-in step2">Scan the QR code</p>
//                 <div className="down-arrow fade-in step2">&#x2193;</div>
//                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
//               </div>
//             </div>

//             <div className="payment-options">
//               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



import React, { useState, useEffect, useRef } from 'react';
import './Payment.css'; // Assuming you have your previous styles in this CSS file
import { useLocation } from 'react-router-dom'; // To handle URL parameters

const Payment = () => {
  const [qrCode, setQrCode] = useState(''); // Base64 QR code string
  const [amount, setAmount] = useState(0); // Amount to be displayed
  const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
  const [expiryTime, setExpiryTime] = useState(1.65 * 60); // 1.65 minutes countdown
  const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
  const [success, setSuccess] = useState(false); // For success animation
  const [failed, setFailed] = useState(false); // For failed payment status
  const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL
  const [successRedirectUrl, setSuccessRedirectUrl] = useState(''); // Added
  const [failureRedirectUrl, setFailureRedirectUrl] = useState(''); // Added

  const location = useLocation(); // To get transaction ID from URL parameters
  const pollingIntervalRef = useRef(null); // To store polling interval ID

  useEffect(() => {
    // Extract transaction ID from URL query parameters
    const query = new URLSearchParams(location.search);
    const transactionIdFromUrl = query.get('transaction_id');

    if (transactionIdFromUrl) {
      setTransactionId(transactionIdFromUrl);
      console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

      // Fetch the QR code from the backend using the transaction ID
      fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
        .then(response => response.json())
        .then(data => {
          console.log("QR Code received from backend:", data.qr_code); // Debugging log
          setQrCode(data.qr_code); // Set the base64 QR code
          setAmount(data.amount); // Set the amount to be displayed
          setCallbackUrl(data.payin_callback_url); // Store the callback URL
          setSuccessRedirectUrl(data.success_redirect_url); // Store success redirect URL
          setFailureRedirectUrl(data.failure_redirect_url); // Store failure redirect URL
        })
        .catch(error => {
          console.error('Error fetching QR code:', error); // Log any error in fetching QR code
        });
    }
  }, [location.search]);

  useEffect(() => {
    if (transactionId) {
      pollingIntervalRef.current = setInterval(() => {
        fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
          .then(response => response.json())
          .then(data => {
            console.log("Payment status:", data.status);
            setPaymentStatus(data.status); // Set the payment status

            if (data.status === 'SUCCESS') {
              // Trigger success animation
              setQrCode(''); // Clear the QR code
              setSuccess(true); // Show success animation
              clearInterval(pollingIntervalRef.current); // Stop polling

              // Redirect to the success redirect URL after success
              setTimeout(() => {
                if (successRedirectUrl) {
                  // Create a form and submit via POST
                  const form = document.createElement('form');
                  form.method = 'POST';
                  form.action = successRedirectUrl;

                  // Add transaction ID as hidden input
                  const input = document.createElement('input');
                  input.type = 'hidden';
                  input.name = 'transaction_id';
                  input.value = transactionId;
                  form.appendChild(input);

                  // Append the form to body and submit it
                  document.body.appendChild(form);
                  form.submit();
                } else if (callbackUrl) {
                  window.location.href = callbackUrl;
                }
              }, 2000);
            } else if (data.status === 'FAILED') {
              // Handle failed payment
              setFailed(true);
              clearInterval(pollingIntervalRef.current); // Stop polling

              // Redirect to the failure redirect URL after failure
              setTimeout(() => {
                if (failureRedirectUrl) {
                  // Create a form and submit via POST
                  const form = document.createElement('form');
                  form.method = 'POST';
                  form.action = failureRedirectUrl;

                  // Add transaction ID as hidden input
                  const input = document.createElement('input');
                  input.type = 'hidden';
                  input.name = 'transaction_id';
                  input.value = transactionId;
                  form.appendChild(input);

                  // Append the form to body and submit it
                  document.body.appendChild(form);
                  form.submit();
                } else if (callbackUrl) {
                  window.location.href = callbackUrl;
                }
              }, 2000);
            }
          })
          .catch(error => {
            console.error('Error fetching payment status:', error);
          });
      }, 3000); // Poll every 3 seconds
    }

    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

  useEffect(() => {
    if (expiryTime > 0) {
      const timer = setInterval(() => {
        setExpiryTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [expiryTime]);

  useEffect(() => {
    if (expiryTime === 0 && paymentStatus === 'PENDING') {
      // Handle failed payment due to expiry
      setFailed(true);
      setPaymentStatus('FAILED');
      clearInterval(pollingIntervalRef.current);

      // Redirect to the failure redirect URL after timeout
      setTimeout(() => {
        if (failureRedirectUrl) {
          // Create a form and submit via POST
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = failureRedirectUrl;

          // Add transaction ID as hidden input
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = 'transaction_id';
          input.value = transactionId;
          form.appendChild(input);

          // Append the form to body and submit it
          document.body.appendChild(form);
          form.submit();
        } else if (callbackUrl) {
          window.location.href = callbackUrl;
        }
      }, 2000);
    }
  }, [expiryTime, paymentStatus, callbackUrl, failureRedirectUrl, transactionId]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="payment-widget-container">
      <div className="payment-widget">
        {/* Success animation */}
        {success ? (
          <div className="success-animation">
            <h2>Payment Successful!</h2>
            <div className="checkmark-circle">
              <div className="checkmark"></div>
            </div>
          </div>
        ) : failed ? (
          <div className="failed-animation">
            <h2>Payment Failed</h2>
            <div className="failed-circle">
              <div className="crossmark"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="header">
              <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
            </div>

            <div className="logo-section">
              <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
            </div>

            <h2 className="payment-title">Scan and Pay using your UPI App</h2>

            {/* Displaying the amount to be paid */}
            <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

            <div className="qr-and-steps">
              <div className="qr-section">
                {/* Render the QR code fetched from the backend */}
                {qrCode ? (
                  <img
                    src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
                    alt="UPI QR Code"
                    className="qr-code"
                  />
                ) : (
                  <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
                )}
                <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
              </div>

              {/* Steps to complete the payment */}
              <div className="payment-steps">
                <p className="step-text fade-in step1">Open your UPI app</p>
                <div className="down-arrow fade-in step1">&#x2193;</div>
                <p className="step-text fade-in step2">Scan the QR code</p>
                <div className="down-arrow fade-in step2">&#x2193;</div>
                <p className="step-text fade-in step3">Enter your PIN and Pay</p>
              </div>
            </div>

            <div className="payment-options">
              <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
              <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
              <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
              <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Payment;
