import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QuickDeposit from './QuickDeposit'; // Import QuickDeposit component
import Payment from './Payment'; // Import Payment component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define the routes */}
          <Route path="/payment" element={<Payment />} /> {/* Payment page */}
          <Route path="/quick-deposit" element={<QuickDeposit />} /> {/* QuickDeposit page */}
          <Route path="/" element={<h1>Welcome! Visit /payment or /quick-deposit</h1>} />
          <Route path="/quick-deposit/:transaction_id" element={<QuickDeposit />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
